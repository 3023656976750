<script setup lang="ts">
  import { useQuickLogin } from './composables/useQuickLogin'
  import { useSentry } from './composables/useSentry'

  import { MdBarcodeReader } from '@kalimahapps/vue-icons'
  import { RouterView } from 'vue-router'

  import AppSettingsButton from '@/components/AppSettingsButton.vue'
  import MainNavigation from '@/components/MainNavigation.vue'
  import MemoryUsage from '@/components/MemoryUsage.vue'
  import DynamicDialog from 'primevue/dynamicdialog'
  import Toast from 'primevue/toast'
  import { useAccount } from '@/composables/useAccount'
  import { usePerformanceProfiling } from '@/composables/usePerformanceProfiling'
  import { usePrinters } from '@/composables/usePrinters'
  import { useScanListener } from '@/composables/useScanListener'
  import { useScanner } from '@/composables/useScanner'

  import { useIdentityStore } from '@/stores/identity'

  const { accountVersion } = useAccount()
  const { registerScanner } = useScanner()
  const { registerListeners, listeners } = useScanListener()

  useQuickLogin()

  registerScanner()
  registerListeners()

  const { initializeSentryUser } = useSentry()
  initializeSentryUser()

  const IdentityStore = useIdentityStore()

  const { refreshPrintersAndPaper } = usePrinters()
  if (IdentityStore.isAuthenticated) {
    refreshPrintersAndPaper()
  }

  const environment = import.meta.env.VITE_ENVIRONMENT
  const { isMemoryUsageDisplayEnabled } = usePerformanceProfiling()
</script>

<template>
  <div class="bf-background-dark bf-text flex min-h-screen flex-col">
    <DynamicDialog />
    <div class="flex flex-1 flex-col">
      <header class="w-full">
        <MainNavigation />
      </header>
      <Toast position="bottom-right" group="br" class="!z-[1200]" />
      <Toast position="bottom-center" group="bc" class="!z-[1200]" />
      <MemoryUsage
        v-if="environment === 'local' && isMemoryUsageDisplayEnabled"
      />
      <RouterView :key="accountVersion" />
    </div>
    <!-- ! Sticky Footer-Toolbar -->
    <div
      v-if="IdentityStore.isAuthenticated"
      class="sticky bottom-1.5 z-[2000] w-min"
    >
      <div class="flex flex-col items-start gap-3">
        <div
          v-if="listeners.length > 0"
          v-tooltip.left="
            `Scan: ${Array.from(new Set(listeners.map((listener) => Array.from(new Set(Object.keys(listener.bindings))).join(',')))).join(',')}`
          "
          class="scale-x-[-1] pr-1.5"
        >
          <h1 class="text-2xl text-primary-500/70">
            <MdBarcodeReader />
          </h1>
        </div>
        <AppSettingsButton />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped></style>
