<script setup lang="ts">
  import { ref, useId, watch } from 'vue'
  import { useBroadcastChannel } from '@vueuse/core'

  import FormInputSwitch from '@/components/FormInputSwitch.vue'
  import InputGroup from 'primevue/inputgroup'

  import { useThemeStore } from '@/stores/theme'

  interface IdentityChannelMessage {
    theme?: 'light' | 'dark'
  }
  const {
    isSupported,
    data,
    // channel,
    post,
    // close,
    // error,
    // isClosed,
  } = useBroadcastChannel<IdentityChannelMessage, IdentityChannelMessage>({
    name: 'bf-identity',
  })
  watch(data, () => {
    if (data.value.theme) {
      themeToggle.value = data.value.theme === 'light'
      ThemeStore.themeSet(data.value.theme)
    }
  })

  const ThemeStore = useThemeStore()
  const themeToggle = ref(ThemeStore.theme === 'light')

  watch(themeToggle, () => {
    if (themeToggle.value) {
      ThemeStore.themeSet('light')
      if (isSupported) {
        post({ theme: 'light' })
      }
    } else {
      ThemeStore.themeSet('dark')
      if (isSupported) {
        post({ theme: 'dark' })
      }
    }
  })
</script>

<template>
  <InputGroup class="flex place-items-center gap-2 px-2">
    <i class="pi pi-moon text-md m-auto text-primary dark:text-white" />
    <FormInputSwitch
      v-model="themeToggle"
      :inputId="`toggle-theme-mode-${useId()}`"
    />
    <i class="pi pi-sun text-md m-auto text-primary dark:text-white" />
  </InputGroup>
</template>

<style lang="postcss" scoped></style>
