<script setup lang="ts">
  import type { MenuItem } from 'primevue/menuitem'

  import { appVersion } from '@/appVersion'
  import { useRoute, useRouter } from 'vue-router'
  import { computed } from 'vue'

  import { isRouteVisible } from '@/modules/permissions/composables/usePermissions'
  import AccountDropdown from '@/components/AccountDropdown.vue'
  import LoaderGlobal from '@/components/LoaderGlobal.vue'
  import ThemeSwitch from '@/components/ThemeSwitch.vue'
  import Menubar from 'primevue/menubar'

  import { useIdentityStore } from '@/stores/identity'

  const router = useRouter()
  const route = useRoute()
  const IdentityStore = useIdentityStore()
  const MenuItems = computed<MenuItem[]>(() => {
    return [
      {
        label: 'About',
        route: {
          name: 'about',
        },
        visible: !IdentityStore.isAuthenticated,
      },
      // Dashboard
      {
        label: 'Dashboard',
        icon: 'pi pi-clipboard',
        route: {
          name: 'dashboard',
        },
        visible: IdentityStore.isAuthenticated,
      },
      {
        label: 'Manage',
        icon: 'pi pi-database',
        items: [
          {
            label: 'Products',
            icon: 'pi pi-id-card',
            route: {
              name: 'product-manager',
            },
            visible: isRouteVisible('product-manager'),
          },
          // Brand
          // Vendors
          // Vendor Products
          // Categories
          // Attributes
          // {
          //   separator: true,
          // },
          // Warehouses
          // Purchase Orders
          // Inventory
          {
            separator: true,
          },
          {
            label: 'Listings',
            icon: 'pi pi-list',
            route: {
              name: 'listing-manager',
            },
            visible: isRouteVisible('listing-manager'),
          },
          {
            separator: true,
          },
          {
            label: 'Promotions',
            icon: 'pi pi-tags',
            route: {
              name: 'promotion-manager',
            },
            visible: isRouteVisible('promotion-manager'),
          },
          // Marketplaces / Sites
          // Channels
          // Orders
          // Customers
          // Service Tickets
          // {
          //   separator: true,
          // },
          // Website Collections
          // Account
          // Integrations
        ],
        visible: IdentityStore.isAuthenticated,
      },
      {
        label: 'Admin',
        icon: 'pi pi-pencil',
        items: [
          {
            label: 'User Settings',
            icon: 'pi pi-user-edit',
            route: {
              name: 'user-settings',
            },
            visible: isRouteVisible('user-settings'),
          },
          {
            label: 'Amazon Competitive',
            icon: 'pi pi-amazon',
            route: {
              name: 'amazon-competitive-reporting',
            },
            visible: isRouteVisible('amazon-competitive-reporting'),
          },
        ],
        visible: IdentityStore.isAuthenticated,
      },
      {
        label: 'Login',
        route: {
          name: 'login',
        },
        icon: 'pi pi-user',
        visible: !IdentityStore.isAuthenticated,
      },
      {
        label: 'Logout',
        icon: 'pi pi-user-minus',
        command: () => {
          if (IdentityStore.isAuthenticated) {
            IdentityStore.logout()
            if (
              route.path !== '/login' &&
              (route.meta?.requiresAuth ?? false)
            ) {
              router.push({
                name: 'login',
                query: {
                  redirect: route.fullPath,
                },
              })
            }
          }
        },
        visible: IdentityStore.isAuthenticated,
      },
    ]
  })
</script>

<template>
  <div class="main-navigation">
    <Menubar
      class="rounded-none border-surface-200 bg-surface-50 dark:border-surface-800 dark:bg-surface-900"
      :model="MenuItems"
    >
      <template #start>
        <div class="flex items-center gap-2">
          <RouterLink to="/">
            <div
              class="text-md font-display cursor-pointer font-bold uppercase md:flex lg:text-xl"
            >
              Bläckfisk
            </div>
          </RouterLink>
          <div
            class="rounded-lg bg-surface-150 px-2 py-0.5 text-sm text-surface-500 ring-1 ring-inset ring-surface-300 dark:bg-surface-750 dark:text-primary-500 dark:ring-primary-900"
          >
            {{ appVersion }}
          </div>
        </div>
      </template>
      <template #item="{ item, props }">
        <div class="p-menubar-item-content">
          <RouterLink
            v-if="item.route"
            v-slot="{ href, navigate }"
            :to="item.route"
            custom
          >
            <a
              :href="href"
              v-bind="props.action"
              class="p-menubar-item-link"
              @click="navigate"
            >
              <span class="p-menubar-item-icon" :class="item.icon" />
              <span class="p-menubar-item-label">{{ item.label }}</span>
              <span
                v-if="(item?.items ?? []).length > 0"
                class="p-menubar-submenu-icon pi pi-caret-down"
              ></span>
            </a>
          </RouterLink>
          <a
            v-else
            :href="item.url"
            :target="item.target"
            v-bind="props.action"
            class="p-menubar-item-link"
          >
            <span class="p-menubar-item-icon" :class="item.icon" />
            <span class="p-menubar-item-label">{{ item.label }}</span>
            <span
              v-if="(item?.items ?? []).length > 0"
              class="p-menubar-submenu-icon pi pi-caret-down"
            ></span>
          </a>
        </div>
      </template>
      <template #end>
        <div class="flex">
          <AccountDropdown />
          <ThemeSwitch class="hidden w-min xs:flex" />
        </div>
      </template>
    </Menubar>
  </div>
  <LoaderGlobal />
</template>

<style lang="postcss" scoped></style>
